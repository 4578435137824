import React from 'react';
import Qoute from "../assets/images/testimonialqoute.svg";
import SeanLee from "../assets/images/SeanLee.svg";
import DaveSpooner from "../assets/images/DaveSpooner.svg";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slides = [
    {
      image: Qoute,
      Message: 'I was amazed by the quality of work the provide and the price at which they provided.',
      HeadShot: SeanLee,
      Name: "Sean Lee",
      Designation: "Director of Operations, NP Digital by Neil Patel"
    },
    {
        image: Qoute,
        Message: 'They do high-quality work, they’re easy to get along with, and they have excellent design chops.',
        HeadShot: DaveSpooner,
        Name: "Dave Spooner",
        Designation: "Co-Founder, Innago LLC"
    },
  ];

const VerticalTestimonial = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        arrows:false,
        autoplay: true,
        useTransform: true,
        cssEase: 'ease-in-out',
    };
  return (
      <>
      <Slider {...settings}>
        {slides.map((slide, index) => (
            <div className="vertical-testimonial-block" key={index}>
                <div className="testimonial-qoute">
                    <img src={slide.image} width="60px" height="50px"/>
                </div>
                <div className="testimonial-message">
                    <p className="text-white">{slide.Message}</p>
                </div>
                <div className="client-box">
                    <div className="image-block">
                        <img src={slide.HeadShot} height="80px" width="80px"/>
                    </div>
                    <div className="client-name">
                        <p className="small text-white">{slide.Name}</p>
                        <p className="extra-small designation">{slide.Designation}</p>
                    </div>
                </div>
            </div>
        ))}
      </Slider>
    </>
  )
}
  
export default VerticalTestimonial