import React, { useEffect } from 'react';
import { Link } from "gatsby";
import SlideUp from '../../components/SlideUpOnScroll';
import Lottie from "lottie-react";

import { gsap } from "gsap";
import SplitType from 'split-type';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useInView } from 'react-intersection-observer';

import Testimonials from "../../assets/Testimonial-lottie.json"

import Clutch from "../../assets/Clutch Rating.json";

import VerticalTestimonial from '../../components/VerticalTestimonials';



const TestimonialSectionV2 = (props) => {

    const [ref, inView] = useInView({
        threshold: 0, // Trigger animation when the div is 50% visible
        triggerOnce: true,
        delay: 2.8
    });

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        
        const testimonialSectionTitle = document.querySelector(".testimonial-section-v2 .section-title");

        gsap.set(testimonialSectionTitle, { autoAlpha: 1 });
        
        const splittestimonialSectionTitle = new SplitType(testimonialSectionTitle, {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tltestimonialSectionTitle = gsap.timeline({
          scrollTrigger:{
            trigger: ".testimonial-section-v2",
          }
        });
        tltestimonialSectionTitle.from(splittestimonialSectionTitle.words,{
        //   duration: 1.2,
          duration: .8,
          yPercent: 150,
          ease: "Power4.easeInOut",
        //   rotationX: 0.5,
        //   rotationY: 0.5,
        //   z: 0.5,
          delay: 1.3,
          stagger: {
            amount: 0.1
          }
        });


        const tlMessageBlock = gsap.timeline({
            scrollTrigger: {
                trigger: ".testimonial-section-v2",
            }
        })
        tlMessageBlock.to(".testimonial-section-v2 .message-block", .8,{
            width: "100%",
            delay:1.3,
            ease: "Power4.easeInOut",
            // rotationX: 0.8,
            // rotationY: 0.8,
            // z: 0.8
        })

        const tlTestimonialLottie = gsap.timeline({
            scrollTrigger: {
                trigger: ".testimonial-section-v2",
            }
        })
        tlTestimonialLottie.to(".testimonial-section-v2 .message-block .vertical-testimonial-block", .8,{
            opacity: 1,
            delay:1.6,
            ease: "Power4.easeInOut",
        })

    },[])

    return (
        <section className="testimonial-section-v2 mt-125">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="content-and-image-block">
                            {/* <SlideUp duration={400} delay={500}> */}
                                <h2 className="h2 section-title extra-spacing">{props.mainTitle}</h2>
                            {/* </SlideUp> */}
                            <SlideUp duration={400} delay={500}>
                            <div className="image-block">
                                <Link to="https://clutch.co/profile/decojent" target="_blank">
                                    {/* <img
                                        src={props.image1x?.sourceUrl}
                                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                        width={props.image1x?.width}
                                        alt={props.image1x?.altText}
                                    /> */}
                                    <div ref={ref} style={{width: "278px", height: "104px"}}>
                                        {inView && (
                                            <Lottie 
                                            animationData={Clutch}
                                            loop={false}
                                        />
                                        )}
                                    </div>
                                </Link>
                            </div>
                            </SlideUp>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                    <div className="col-lg-12">
                        <div className="message-block">
                            {/* {props.list?.map((object, i) => {
                                return (
                                    <SlideUp duration={400} delay={500}>
                                    <div className="message" key={shortid.generate()}>
                                        <div className="qoute">
                                            <img
                                                src={object.qouteIcon?.sourceUrl}
                                                alt={object.qouteIcon?.altText}
                                            />
                                        </div>
                                        <p className="testimonial">{object.message}</p>
                                        <div className="client-details">
                                            <div className="logo">
                                                <img
                                                    src={object.clientImage?.sourceUrl}
                                                    alt={object.clientImage?.altText}
                                                />
                                            </div>
                                            <div className="name">
                                                <p className="name small">{object.clientName}</p>
                                                <p className="designation extra-small">{object.company}</p>
                                            </div>
                                        </div>
                                    </div>
                                    </SlideUp>
                                )
                            })} */}
                            {/* <Lottie animationData={Testimonials} loop={true} className="testimonial-lottie"/> */}
                            <VerticalTestimonial />
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default TestimonialSectionV2